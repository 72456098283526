import { restful } from '../utils/restful'
import axios from '../plugins/axios/index'
//在有token的情况下 获取用户信息
export function getUserInfoHttp(par = {}) {
  var url = '/api/user/userInfo'
  return restful(par, url, true)
}

//登录
export function loginHttp(par = {}) {
  var url = '/api/user/login'
  return restful(par, url, false)
}

//QQ登录
export function qqLoginHttp(par = {}) {
  var url = '/api/user/qqLogin'
  return restful(par, url, false)
}

//注册
export function registerHttp(par = {}) {
  var url = '/api/user/register'
  return restful(par, url, false)
}

//修改用户密码：登陆密码-支付密码（type：1登陆密码，type：2支付密码）
export function changeUserPasswordHttp(par = {}) {
  var url = '/api/user/changeUserPassword'
  return restful(par, url, true)
}

//忘记密码
export function forgetUserPassWordHttp(par = {}) {
  var url = '/api/user/forgetUserPassWord'
  return restful(par, url, false)
}
// 口令生成
export function getAttentionHttp(par = {}) {
  var url = `/api/goods/checkAttention`
  return restful(par, url, true)
}

// 手机短信登录
export function loginBySmCodeHttp(par = {}) {
  var url = `/api/user/loginBySmCode`
  return restful(par, url, false)
}

// 新增金币角色卡
export function addUserRoleCardHttp(par = {}) {
  var url = `/api/roleCard/addUserRoleCardInfo`
  return restful(par, url, true)
}

// 删除金币角色卡
export function removeUserRoleCardHttp(par = {}) {
  var url = `/api/roleCard/removeRoleCard`
  return restful(par, url, true)
}

// 获取金币角色卡
export function getUserRoleCardHttp(par = {}) {
  var url = `/api/roleCard/roleCardInfoList`
  return restful(par, url, true)
}

// 收藏商品列表
export function goodsUserFollowHttp(par = {}) {
  var url = `/api/user/goodsUserFollow`
  return restful(par, url, true)
}

// 收藏商品
export function followGoodsHttp(par = {}) {
  var url = `/api/user/followGoods`
  return restful(par, url, true)
}

// 秒杀商品列表
export function getSeckillOrderHttp(par = {}) {
  var url = `/api/user/queryUserSeckillOrderList`
  return restful(par, url, true)
}

// 修改用户信息
export function changeUserInfoHttp(par = {}) {
  var url = `/api/user/changeUserUsualInfo`
  return restful(par, url, true)
}

// 改绑手机号
export function changeBindPhoneHttp(par = {}) {
  var url = `/api/user/changeBindPhone`
  return restful(par, url, true)
}

// 绑定支付宝/微信/银行卡( 1 支付宝 2 微信 3 银行卡 )
export function bindAccountNumHttp(par = {}) {
  var url = `/api/user/bindAccountNum`
  return restful(par, url, true)
}

// 资金管理接口（统计数据）
export function capitalManagementHttp(par = {}) {
  var url = `/api/user/capitalManagement`
  return restful(par, url, true)
}


// 生成用户推荐ma
export function getUserInviteCodeHttp(par = {}) {
  var url = `/api/user/getUserInviteCode`
  return restful(par, url, true)
}

// 获取推荐用户列表
export function getInviteCodeUserListHttp(par = {}) {
  var url = `/api/user/queryRecommendUserIdList`
  return restful(par, url, true)
}

//获取推荐用户订单列表
export function getrecommendgoosfor(par = {}) {
  var url = `/api/user/queryRecommendOrderList`
  return restful(par, url, true)
}

//推荐商品
export function Recommen(par = {}) {
  var url = '/api/user/queryRecommendGoodsList'
  return restful(par, url, true)
}


// 读取实名认证信息
export function getCertifyInfo(par = {}) {
  var url = `/api/comm/queryCertificateInfo`
  return restful(par, url, true)
}


// 获取奖品列表
export function getRaffle(par = {}) {
  var url = `/api/comm/getRaffle`
  return restful(par, url, true)
}

// 获取中奖的客户
export function getWinningRaffleList(par = {}) {
  var url = `/api/comm/getWinningRaffleList`
  return restful(par, url, true)
}

// 获取用户中奖记录
export function getUserRaffleList(par = {}) {
  var url = `/api/user/getUserRaffleList`
  return restful(par, url, true)
}

// 获取推广用户
export function queryRecommendUserIdList(par = {}) {
  var url = `/api/user/queryRecommendUserIdList`
  return restful(par, url, true)
}

// 获取推广用户订单
export function queryRecommendOrderList(par = {}) {
  var url = `/api/user/queryRecommendOrderList`
  return restful(par, url, true)
}

// 记录客户抽奖记录 POST！！！
export function createUserRaffle(par = {}) {
  var url = `/api/user/createUserRaffle`
  return restful(par, url, true)
}
//获取客服ID
export function getCustomerId(par = {}) {
  var url = `/api/user/getCustomerId`
  return restful(par, url, true)
}

//获取用户个人信息
export function userRealInfo(par = {}) {
  var url = `/api/user/userRealInfo`
  return restful(par, url, true)
}

//修改个人信息保存
export function updateUserRealInfo(par = {}) {
  var url = `/api/user/updateUserRealInfo`
  return restful(par, url, true)
}


//获取推广协议

export function protocol(par = {}) {
  var url = `/api/comm/newsInfo?newsId=1001`
  return restful(par, url, true)
}

//百度推广

export function baiduPromotion(par = {}) {
  let headers = {}
  headers['Content-Type'] = "application/json"
  var url = `/ocpcapi/api/uploadConvertData`
  let data = JSON.stringify(par)
  return axios({ url, method: "POST", data, headers, withCredentials: true })
}

//推荐购买订单
export function queryRecommendGoodsList(par = {}) {
  var url = `/api/recommendOrderLogs/list`
  return restful(par, url, true)
}

//推荐购买商品
export function queryrecommendGoodsLogs(par = {}) {
  var url = `/api/recommendGoodsLogs/list`
  return restful(par, url, true)
}


//微信扫码登录接口
export function weChatScanCodeloginApi(par = {}) {
  var url = `/api/user/weChatLogin`
  return restful(par, url, true)
}



// 人工审核身份
export function manualReviewIDApi(par = {}) {
  var url = '/api/user/manualReviewID'
  return restful(par, url, true)
}