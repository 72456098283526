
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { Queryusertype } from "@/api/team";
@Component({
  name: "centerLeftNav",
})
export default class centerLeftNav extends Vue {
  private routeText: string = "";
  private nowSubmenuIndex: any = -1;
  public getCookie: any;
  public phone: string = "";
  public userId: string = "";
  public isuser: boolean = false;
  private leftNavList: Array<any> = [
    // {
    //   id: 1,
    //   title:'个人中心',
    //   icon:require('~/static/image/center/centerLeftNav/title_1_black.png'),
    //   selectedIcon:require('~/static/image/center/centerLeftNav/title_1_yellow.png'),
    //   href:'#',
    //   options:[
    //     {id: 11, title:'个人信息',href:'/center/myself/myselfInfo',},
    //     {id: 12, title:'账号安全',href:'/center/myself/accountSecurity',},
    //   ]
    // },
    {
      id: 1,
      title: "我的账户",
      // icon:require('~/static/image/center/centerLeftNav/grzx-mjzx-myUser-tubiao3.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/grzx-mjzx-myUser-tubiao2.png'),
      icon: require("~/static/image/center/centerLeftNav/my-account-page.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/my-account-page.png"),
      href: "/center/myself",
      options: [
        { id: 21, title: "个人资料", href: "/center/myself/myselfInfo" },
        // {id: 22, title:'商城速购订单',href:'/center/buyers/buyFastGold',},
        {
          id: 23,
          title: "实名认证",
          href: "/center/myself/identityAuthentication",
        },

        { id: 24, title: "账户安全", href: "/center/myself/accountSecurity" },
        // {id: 25, title:'我的保障理赔',href:'/center/buyers/buyGuarantee',},
      ],
    },
    {
      id: 2,
      title: "买家中心",
      // icon:require('~/static/image/center/centerLeftNav/grzx-mjzx-myBuy-tubiao3.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/grzx-mjzx-myBuy-tubiao2.png'),
      icon: require("~/static/image/center/centerLeftNav/buyer-center-page.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/buyer-center-page.png"),
      href: "/center/buyers",
      options: [
        { id: 21, title: "我的购买订单", href: "/center/buyers/buyGoodsOrder" },
        // {id: 22, title:'商城速购订单',href:'/center/buyers/buyFastGold',},
        { id: 23, title: "我收藏的商品", href: "/center/buyers/buyLikedGoods" },
        // {id: 25, title:'我的保障理赔',href:'/center/buyers/buyGuarantee',},
        {
          id: 45,
          title: "我秒杀的商品",
          href: "/center/buyers/buySeckillOrder",
        },
        {
          id: 46,
          title: "我的抽奖记录",
          href: "/center/buyers/lotteryRecords",
        },

        { id: 47, title: "我的砍价订单", href: "/center/buyers/BuyBargain" },
      ],
    },
    {
      id: 3,
      title: "卖家中心",
      // icon:require('~/static/image/center/centerLeftNav/grzx-mjzx-mySell-tubiao3.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/grzx-mjzx-mySell-tubiao2.png'),
      icon: require("~/static/image/center/centerLeftNav/seller-center-page.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/seller-center-page.png"),
      href: "/center/sell",
      options: [
        { id: 31, title: "我要卖", href: "/center/sell/sellTab" },
        { id: 32, title: "我出售的商品", href: "/center/sell/sellOutGoods" },
        { id: 33, title: "我出售的订单", href: "/center/sell/sellOrder" },
        { id: 34, title: "我收到的砍价订单", href: "/center/sell/Sellreceive" },
      ],
    },
    {
      id: 4,
      title: "资产中心",
      // icon:require('~/static/image/center/centerLeftNav/grzx-mjzx-assetc-tubiao3.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/grzx-mjzx-assetc-tubiao2.png'),
      icon: require("~/static/image/center/centerLeftNav/asset-center-page.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/asset-center-page.png"),
      href: "/center/assets",
      options: [
        { id: 41, title: "资金管理", href: "/center/assets/assetsFinancing" },
      ],
    },
    {
      id: 5,
      title: "我的推广",
      // icon:require('~/static/image/center/centerLeftNav/title_6_black.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/title_6_yellow.png'),
      icon: require("~/static/image/center/centerLeftNav/tg-list-page.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/tg-list-page.png"),
      href: "/center/extend",
      options: [
        { id: 51, title: "推广二维码", href: "/center/extend/qrCode" },
        {
          id: 52,
          title: "购买订单推广",
          href: "/center/extend/extendMyExtension",
        },
        { id: 53, title: "出售商品推广", href: "/center/extend/sellgooSn" },
      ],
    },
    {
      id: 6,
      title: "浏览足迹",
      // icon:require('~/static/image/center/centerLeftNav/title_6_black.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/title_6_yellow.png'),
      icon: require("~/static/image/center/centerLeftNav/browse-steps-page.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/browse-steps-page.png"),
      href: "/center/log",
      options: [{ id: 61, title: "浏览足迹", href: "/center/log/history" }],
    },
    {
      id: 8,
      title: "反馈建议",
      // icon:require('~/static/image/center/centerLeftNav/from2.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/from1.png'),
      icon: require("~/static/image/center/centerLeftNav/feed-back-suggestion.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/feed-back-suggestion.png"),
      href: "/center/feedback",
      options: [
        {
          id: 81,
          title: "投诉客服",
          href: "/center/feedback/feedbackandsuggestion",
        },
        {
          id: 82,
          title: "反馈建议",
          href: "/center/feedback/feedbackrecording",
        },
        {
          id: 82,
          title: "反馈记录",
          href: "/center/feedback/complaintcustomerservice",
        },
      ],
    },
    {
      id: 7,
      title: "游戏活动",
      // icon:require('~/static/image/center/centerLeftNav/title_6_black.png'),
      // selectedIcon:require('~/static/image/center/centerLeftNav/title_6_yellow.png'),
      icon: require("~/static/image/center/centerLeftNav/game-activities-page.png"),
      selectedIcon: require("~/static/image/center/centerLeftNav/game-activities-page.png"),
      href: "/center/activity",
      options: [
        {
          id: 71,
          title: "集结号",
          href: "/center/activity/play",
          show: this.isuser,
        },
      ],
    },
  ];
  @Watch("$route")
  routechange(to: any, from: any) {
    this.routeText = to.path;
  }

  created() {
    this.phone = this.getCookie("phoneNum");
    this.userId = this.getCookie("userId");
  }

  mounted() {
    //  this.isUser()
    if (window.location.host.includes("192")) {
      this.leftNavList[1].options.splice(3, 0, {
        id: 26,
        title: "我砍价的商品",
        href: "/center/buyers/buyBargainGoods",
      });
      this.leftNavList[2].options.splice(3, 0, {
        id: 34,
        title: "砍价中的商品",
        href: "/center/sell/sellBargainGoods",
      });
    }
    console.log(this.$route.path, "=========route============");
    this.routeText = this.$route.path;
  }

  // async isUser(){ //查询用户是否为团长
  //     let res = await Queryusertype({params:{
  //       userId:this.userId,
  //       phone:this.phone
  //     }})
  //     if(res?.data.code==0){
  //       this.isuser = true
  //     }else{
  //       this.isuser = false
  //     }

  //     console.log(res,'+++++++++++++++++是否为团长');
  // }

  handleOpen(key: any, keyPath: any) {
    console.log(key, keyPath, "kkkkkk");
  }
  handleClose(key: any, keyPath: any) {
    console.log(key, keyPath, "gggggg");
  }
  clickitem(v: any) {
    console.log(v, "ewfe");
  }
  mouseOverSubmenu(index: any) {
    this.nowSubmenuIndex = index;
  }
  mouseLeaveSubmenu() {
    this.nowSubmenuIndex = -1;
  }
}
