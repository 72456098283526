
import { Component, Vue } from "vue-property-decorator";
import { getUserInfoHttp, goodsUserFollowHttp } from "~/api/user";
import { queryOrderListHttp } from "~/api/order";
@Component({
  name: "centerLayout",
  components: {
    djhNav8: require("~/components/comm/djhNav/djhNav8.vue").default,
    breadCrumb: require("~/components/comm/breadCrumb/index.vue").default,
    centerMemberInfo2: require("~/components/comm/center/centerMemberInfo2.vue")
      .default,
    centerLeftNav: require("~/components/comm/center/centerLeftNav.vue")
      .default,
    djhFooter: require("~/components/comm/djhFooter/index.vue").default,
    floatMenu9: require("~/components/comm/floatMenu/index9.vue").default,
    footerService4: require("~/components/comm/djhFooter/footerService4.vue")
      .default,
    floatLeft1: require("~/components/comm/floatLeft/index.vue").default,
    kefuVerify: require("~/components/comm/kefuVerify/index.vue").default,
  },
  // 修改网页头
  head() {
    return {
      script: [
        {
          src:
            this.$store.state.web.distributorsObject.distBaseConf
              .statTrafScript &&
            JSON.parse(
              this.$store.state.web.distributorsObject.distBaseConf
                .statTrafScript
            ).sllSl
              ? JSON.parse(
                  this.$store.state.web.distributorsObject.distBaseConf
                    .statTrafScript
                ).sllSl
              : "",
        }, //360收录
        {
          src:
            this.$store.state.web.distributorsObject.distBaseConf
              .statTrafScript &&
            JSON.parse(
              this.$store.state.web.distributorsObject.distBaseConf
                .statTrafScript
            ).baiduSl
              ? JSON.parse(
                  this.$store.state.web.distributorsObject.distBaseConf
                    .statTrafScript
                ).baiduSl
              : "",
        }, //百度收录
        // { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).sougouSl)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).sougouSl:'' },//搜狗收录
        // { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).shenmaSl)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).shenmaSl:'' },//神马收录
        {
          src:
            this.$store.state.web.distributorsObject.distBaseConf
              .statTrafScript &&
            JSON.parse(
              this.$store.state.web.distributorsObject.distBaseConf
                .statTrafScript
            ).baiduTj
              ? JSON.parse(
                  this.$store.state.web.distributorsObject.distBaseConf
                    .statTrafScript
                ).baiduTj
              : "",
        }, //百度统计
      ],
      title: this.$store.state.web.distributorsObject.distBaseConf.websiteName,
      meta: [
        {
          hid: "description",
          name: "description", //网站描述
          content:
            this.$store.state.web.distributorsObject.distBaseConf.websiteDesc, //网站描述
        },
        {
          hid: "keywords",
          name: "keywords", //网站关键字
          content:
            this.$store.state.web.distributorsObject.distBaseConf.webKeyWordStr, //网站关键字
        },
      ],
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href:
            "https://oss1.hk1686.com/" +
            this.$store.state.web.distributorsObject.pictArea.webIcon,
        },
      ],
    };
  },
})
export default class centerLayout extends Vue {
  private breadCrumbList: Array<any> = [
    { title: "首页", url: "/", fontWeight: "700", color: "#606266" },
    { title: "个人中心", url: "#", fontWeight: "400", color: "#606266" },
  ];
  private favList: any = {};
  private userInfo: any = {};
  private orderList: any = {};
  public userinfolist: any = [];
  async mounted() {
    console.log("测试信号dklsjfkljkl");

    let body: any = document.querySelectorAll(
      "body"
    ) as NodeListOf<HTMLElement>;
    body[0].setAttribute("style", "background-color:#EDEDED");
    try {
      let [userInfo, orderList, favList] = await Promise.all([
        getUserInfoHttp(),
        queryOrderListHttp({ params: { pageSize: 5 } }),
        goodsUserFollowHttp({ params: { pageSize: 5 } }),
      ]);
      if (
        userInfo.data.code === 0 &&
        orderList.data.code === 0 &&
        favList.data.code === 0
      ) {
        this.userInfo = userInfo.data.data;
        this.orderList = orderList.data.data;
        this.favList = favList.data.data;
      } else {
        try {
          let users = await getUserInfoHttp();
          if (users.data.code === 0) {
            this.userInfo = users.data.data;

            this.orderList = {};
            this.favList = {};
          } else {
            this.$router.push("/login");
          }
        } catch (error) {
          this.$router.push("/login");
        }
      }
    } catch (error) {
      console.log(error);
    }
    console.log(this.userInfo, this.orderList, this.favList, "wecnionkl1");
  }
}
