import Vue from 'vue'
import locale from 'element-ui/lib/locale/lang/en'

// 全局引用
// import Element from 'element-ui'

// 按需引用
import {
  Switch,
  Button,
  Input,
  InputNumber,
  Checkbox,
  Radio,
  RadioGroup,
  CheckboxGroup,
  Select,
  Option,
  Upload,
  Notification,
  Message,
  MessageBox,
  Carousel,
  CarouselItem,
  Dialog,
  Rate,
  Pagination,
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Image,
  Popover,
  Loading,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  TabPane, 
  Tabs, 
  Avatar, 
  Progress, 
  Tooltip,
  Statistic,
  Empty,
  TimePicker,
  Form,
  FormItem,
  Drawer,
  Card,
  Steps, 
  Tag,
} from 'element-ui'

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

// 自定义主题样式(这里我们会在这个文件内引入我们所需的组件的样式)
// import '@/assets/scss/element-variables.scss'

// Vue.use(Element, { locale })




// 按需使用
export default ()=>{
  Vue.component(Message.name, Message);
  Vue.component(MessageBox.name, MessageBox);
  Vue.component(Notification.name, Notification);
  Vue.component(CollapseTransition.name, CollapseTransition)
  Vue.use(Button);
  Vue.use(TimePicker, { locale });
  Vue.use(Switch);
  Vue.use(TabPane);
  Vue.use(Avatar);
  Vue.use(Progress);
  Vue.use(Tooltip);
  Vue.use(Tabs);
  Vue.use(Input, { locale })
  Vue.use(InputNumber, { locale })
  Vue.use(Checkbox, { locale })
  Vue.use(Select, { locale })
  Vue.use(Option, { locale })
  Vue.use(Upload, { locale })
  Vue.use(Carousel, { locale })
  Vue.use(CarouselItem, { locale })
  Vue.use(Dialog, { locale })
  Vue.use(Rate, { locale })
  Vue.use(Radio, { locale })
  Vue.use(RadioGroup, { locale })
  Vue.use(CheckboxGroup, { locale })
  Vue.use(Pagination, { locale })
  Vue.use(DatePicker, { locale })
  Vue.use(Dropdown, { locale })
  Vue.use(DropdownMenu, { locale })
  Vue.use(DropdownItem, { locale })
  Vue.use(Menu, { locale })
  Vue.use(Submenu, { locale })
  Vue.use(MenuItemGroup, { locale })
  Vue.use(MenuItem, { locale })
  Vue.use(Form, { locale })
  Vue.use(FormItem, { locale })
  Vue.use(Image, { locale })
  Vue.use(Popover);
  Vue.use(Statistic);
  Vue.use(Empty);
  Vue.use(TimePicker)
  Vue.use(Drawer)
  Vue.use(Card)
  Vue.use(Steps)
  Vue.use(Tag)
  
  Vue.use(Loading.directive);
  Vue.prototype.$notify = Notification;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$message = Message;
  Vue.prototype.$loading = Loading.service;

}


