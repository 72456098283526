import { restful } from '../utils/restful'

//分站获取分销商信息
export function getDistributorHttp(par = {}) {
  var url = `/api/dist/homeConf`
  return restful(par, url, false)
}

// 网站轮播图
export function swiperHttp(par = {}) {
  var url = '/api/dist/swiper'
  return restful(par, url, false)
}

// 最新成交
export function latestDealRecordHttp(par = {}) {
  var url = '/api/dist/latestDealRecord'
  return restful(par, url, false)
}

// 分站保额列表
export function distEnsureListHttp(par = {}) {
  var url = '/api/dist/distEnsureList'
  return restful(par, url, true)
}

// 主播列表
export function getAnchorList(par = {}) {
  var url = 'api/goods/getNadvertList'
  return restful(par, url, false)
}

// 卖号选项列表
export function getSelectList(par = {}) {
  var url = '/api/comm/allGameList'
  return restful(par, url, true)
}
