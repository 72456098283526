import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4e23121c = () => interopDefault(import('..\\pages\\allgame.vue' /* webpackChunkName: "pages/allgame" */))
const _62dea8b2 = () => interopDefault(import('..\\pages\\auctionPage\\index.vue' /* webpackChunkName: "pages/auctionPage/index" */))
const _a5e4e78c = () => interopDefault(import('..\\pages\\black\\index.vue' /* webpackChunkName: "pages/black/index" */))
const _2cbc3ea6 = () => interopDefault(import('..\\pages\\center\\index.vue' /* webpackChunkName: "pages/center/index" */))
const _394bd9b9 = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _6a0b7f2e = () => interopDefault(import('..\\pages\\Conversations\\index.vue' /* webpackChunkName: "pages/Conversations/index" */))
const _3d420cd2 = () => interopDefault(import('..\\pages\\dealshow\\index.vue' /* webpackChunkName: "pages/dealshow/index" */))
const _6df52d2f = () => interopDefault(import('..\\pages\\forgetPassword.vue' /* webpackChunkName: "pages/forgetPassword" */))
const _a2cd7b76 = () => interopDefault(import('..\\pages\\gj-wzry\\index.vue' /* webpackChunkName: "pages/gj-wzry/index" */))
const _8fb28d16 = () => interopDefault(import('..\\pages\\jump.vue' /* webpackChunkName: "pages/jump" */))
const _809749c2 = () => interopDefault(import('..\\pages\\kfyz\\index.vue' /* webpackChunkName: "pages/kfyz/index" */))
const _73080b95 = () => interopDefault(import('..\\pages\\log\\index.vue' /* webpackChunkName: "pages/log/index" */))
const _7fffbc9c = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _394004f1 = () => interopDefault(import('..\\pages\\moreAnchor\\index.vue' /* webpackChunkName: "pages/moreAnchor/index" */))
const _4ef184b0 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _0da241ea = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _f9f7904e = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _6be4ca43 = () => interopDefault(import('..\\pages\\wares\\index.vue' /* webpackChunkName: "pages/wares/index" */))
const _119615dc = () => interopDefault(import('..\\pages\\Bargbuy\\Babuy.vue' /* webpackChunkName: "pages/Bargbuy/Babuy" */))
const _1bb7ed40 = () => interopDefault(import('..\\pages\\Bargbuy\\Partialpayment.vue' /* webpackChunkName: "pages/Bargbuy/Partialpayment" */))
const _65cf29b6 = () => interopDefault(import('..\\pages\\black\\blackList.vue' /* webpackChunkName: "pages/black/blackList" */))
const _0ffe1c9d = () => interopDefault(import('..\\pages\\black\\center.vue' /* webpackChunkName: "pages/black/center" */))
const _8897b200 = () => interopDefault(import('..\\pages\\black\\releaseBlack.vue' /* webpackChunkName: "pages/black/releaseBlack" */))
const _87ae0b3c = () => interopDefault(import('..\\pages\\black\\zhgs.vue' /* webpackChunkName: "pages/black/zhgs" */))
const _e0e703f4 = () => interopDefault(import('..\\pages\\center\\buyers\\index.vue' /* webpackChunkName: "pages/center/buyers/index" */))
const _926860d8 = () => interopDefault(import('..\\pages\\center\\sell\\index.vue' /* webpackChunkName: "pages/center/sell/index" */))
const _28cd5e62 = () => interopDefault(import('..\\pages\\dealshow\\selltime.vue' /* webpackChunkName: "pages/dealshow/selltime" */))
const _1b516d7f = () => interopDefault(import('..\\pages\\fastGold\\fastGoldOrder.vue' /* webpackChunkName: "pages/fastGold/fastGoldOrder" */))
const _08d2bd0b = () => interopDefault(import('..\\pages\\gj-wzry\\hpjyEvaluator.vue' /* webpackChunkName: "pages/gj-wzry/hpjyEvaluator" */))
const _308dee3b = () => interopDefault(import('..\\pages\\specilPage\\createAccountInfo.vue' /* webpackChunkName: "pages/specilPage/createAccountInfo" */))
const _bb63ea56 = () => interopDefault(import('..\\pages\\specilPage\\downloadApp.vue' /* webpackChunkName: "pages/specilPage/downloadApp" */))
const _5ba4d774 = () => interopDefault(import('..\\pages\\specilPage\\hpjyEvaluator.vue' /* webpackChunkName: "pages/specilPage/hpjyEvaluator" */))
const _170ca2df = () => interopDefault(import('..\\pages\\center\\activity\\play.vue' /* webpackChunkName: "pages/center/activity/play" */))
const _f560a6f6 = () => interopDefault(import('..\\pages\\center\\assets\\assetsFinancing.vue' /* webpackChunkName: "pages/center/assets/assetsFinancing" */))
const _0771a970 = () => interopDefault(import('..\\pages\\center\\buyers\\BuyBargain.vue' /* webpackChunkName: "pages/center/buyers/BuyBargain" */))
const _d9070784 = () => interopDefault(import('..\\pages\\center\\buyers\\buyBargainGoods.vue' /* webpackChunkName: "pages/center/buyers/buyBargainGoods" */))
const _73c20c7e = () => interopDefault(import('..\\pages\\center\\buyers\\buyComplaint.vue' /* webpackChunkName: "pages/center/buyers/buyComplaint" */))
const _128a93b6 = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGold.vue' /* webpackChunkName: "pages/center/buyers/buyFastGold" */))
const _1383adb2 = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGoldDetail.vue' /* webpackChunkName: "pages/center/buyers/buyFastGoldDetail" */))
const _5b05b3f2 = () => interopDefault(import('..\\pages\\center\\buyers\\buyGoodsOrder.vue' /* webpackChunkName: "pages/center/buyers/buyGoodsOrder" */))
const _8d38f5c4 = () => interopDefault(import('..\\pages\\center\\buyers\\buyGuarantee.vue' /* webpackChunkName: "pages/center/buyers/buyGuarantee" */))
const _84e074fa = () => interopDefault(import('..\\pages\\center\\buyers\\buyLikedGoods.vue' /* webpackChunkName: "pages/center/buyers/buyLikedGoods" */))
const _4516e4d5 = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderDetail.vue' /* webpackChunkName: "pages/center/buyers/buyOrderDetail" */))
const _073665b2 = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/buyOrderInfo" */))
const _1cd8bfb9 = () => interopDefault(import('..\\pages\\center\\buyers\\buySeckillOrder.vue' /* webpackChunkName: "pages/center/buyers/buySeckillOrder" */))
const _06eb2696 = () => interopDefault(import('..\\pages\\center\\buyers\\lotteryRecords.vue' /* webpackChunkName: "pages/center/buyers/lotteryRecords" */))
const _353e9d11 = () => interopDefault(import('..\\pages\\center\\buyers\\sellerOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/sellerOrderInfo" */))
const _6848be1a = () => interopDefault(import('..\\pages\\center\\extend\\extendMyExtension.vue' /* webpackChunkName: "pages/center/extend/extendMyExtension" */))
const _39d2de24 = () => interopDefault(import('..\\pages\\center\\extend\\qrCode.vue' /* webpackChunkName: "pages/center/extend/qrCode" */))
const _7488542a = () => interopDefault(import('..\\pages\\center\\extend\\sellgooSn.vue' /* webpackChunkName: "pages/center/extend/sellgooSn" */))
const _2861732d = () => interopDefault(import('..\\pages\\center\\feedback\\complaintcustomerservice.vue' /* webpackChunkName: "pages/center/feedback/complaintcustomerservice" */))
const _2acdb665 = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackandsuggestion.vue' /* webpackChunkName: "pages/center/feedback/feedbackandsuggestion" */))
const _16c8c18a = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackrecording.vue' /* webpackChunkName: "pages/center/feedback/feedbackrecording" */))
const _dcbeb5a8 = () => interopDefault(import('..\\pages\\center\\log\\history.vue' /* webpackChunkName: "pages/center/log/history" */))
const _0fc341f9 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\index.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/index" */))
const _57e8fdc0 = () => interopDefault(import('..\\pages\\center\\myself\\buyIdentity.vue' /* webpackChunkName: "pages/center/myself/buyIdentity" */))
const _0574eec8 = () => interopDefault(import('..\\pages\\center\\myself\\cashWithdrawal.vue' /* webpackChunkName: "pages/center/myself/cashWithdrawal" */))
const _67c73228 = () => interopDefault(import('..\\pages\\center\\myself\\changeEmail.vue' /* webpackChunkName: "pages/center/myself/changeEmail" */))
const _9bd3e922 = () => interopDefault(import('..\\pages\\center\\myself\\changePayPassword.vue' /* webpackChunkName: "pages/center/myself/changePayPassword" */))
const _a7e22a0c = () => interopDefault(import('..\\pages\\center\\myself\\changePhone.vue' /* webpackChunkName: "pages/center/myself/changePhone" */))
const _99a777b8 = () => interopDefault(import('..\\pages\\center\\myself\\depositWithdraw.vue' /* webpackChunkName: "pages/center/myself/depositWithdraw" */))
const _d7353fac = () => interopDefault(import('..\\pages\\center\\myself\\identityAuthentication.vue' /* webpackChunkName: "pages/center/myself/identityAuthentication" */))
const _93aa29cc = () => interopDefault(import('..\\pages\\center\\myself\\myselfInfo.vue' /* webpackChunkName: "pages/center/myself/myselfInfo" */))
const _12f40c7b = () => interopDefault(import('..\\pages\\center\\myself\\recharge.vue' /* webpackChunkName: "pages/center/myself/recharge" */))
const _a52c3fbc = () => interopDefault(import('..\\pages\\center\\myself\\thirdPartyBinding.vue' /* webpackChunkName: "pages/center/myself/thirdPartyBinding" */))
const _62af8104 = () => interopDefault(import('..\\pages\\center\\sell\\sellBargainGoods.vue' /* webpackChunkName: "pages/center/sell/sellBargainGoods" */))
const _2da357de = () => interopDefault(import('..\\pages\\center\\sell\\sellOrder.vue' /* webpackChunkName: "pages/center/sell/sellOrder" */))
const _a2d58170 = () => interopDefault(import('..\\pages\\center\\sell\\sellOutGoods.vue' /* webpackChunkName: "pages/center/sell/sellOutGoods" */))
const _13ba22f3 = () => interopDefault(import('..\\pages\\center\\sell\\Sellreceive.vue' /* webpackChunkName: "pages/center/sell/Sellreceive" */))
const _374f5fda = () => interopDefault(import('..\\pages\\center\\sell\\sellSuccess.vue' /* webpackChunkName: "pages/center/sell/sellSuccess" */))
const _67ae1ab6 = () => interopDefault(import('..\\pages\\center\\sell\\sellTab.vue' /* webpackChunkName: "pages/center/sell/sellTab" */))
const _9c1e0e58 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePassword.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePassword" */))
const _1035b385 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePhone.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePhone" */))
const _134f02ca = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _42c2b662 = () => interopDefault(import('..\\pages\\black\\_sn.vue' /* webpackChunkName: "pages/black/_sn" */))
const _0dab8352 = () => interopDefault(import('..\\pages\\detail\\_sn.vue' /* webpackChunkName: "pages/detail/_sn" */))
const _c7e13634 = () => interopDefault(import('..\\pages\\JDetail\\_sn.vue' /* webpackChunkName: "pages/JDetail/_sn" */))
const _4b5c22bc = () => interopDefault(import('..\\pages\\news\\_code.vue' /* webpackChunkName: "pages/news/_code" */))
const _196d749a = () => interopDefault(import('..\\pages\\goods\\_sn\\order.vue' /* webpackChunkName: "pages/goods/_sn/order" */))
const _4b54fd66 = () => interopDefault(import('..\\pages\\goods\\_sn\\pay.vue' /* webpackChunkName: "pages/goods/_sn/pay" */))
const _4f8bc6b0 = () => interopDefault(import('..\\pages\\goods\\_sn\\success.vue' /* webpackChunkName: "pages/goods/_sn/success" */))
const _3f8febf3 = () => interopDefault(import('..\\pages\\wares\\_sn\\order.vue' /* webpackChunkName: "pages/wares/_sn/order" */))
const _6ede90e6 = () => interopDefault(import('..\\pages\\wares\\_sn\\pay.vue' /* webpackChunkName: "pages/wares/_sn/pay" */))
const _2d7032e8 = () => interopDefault(import('..\\pages\\wares\\_sn\\success.vue' /* webpackChunkName: "pages/wares/_sn/success" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/allgame",
    component: _4e23121c,
    name: "allgame"
  }, {
    path: "/auctionPage",
    component: _62dea8b2,
    name: "auctionPage"
  }, {
    path: "/black",
    component: _a5e4e78c,
    name: "black"
  }, {
    path: "/center",
    component: _2cbc3ea6,
    name: "center"
  }, {
    path: "/contact",
    component: _394bd9b9,
    name: "contact"
  }, {
    path: "/Conversations",
    component: _6a0b7f2e,
    name: "Conversations"
  }, {
    path: "/dealshow",
    component: _3d420cd2,
    name: "dealshow"
  }, {
    path: "/forgetPassword",
    component: _6df52d2f,
    name: "forgetPassword"
  }, {
    path: "/gj-wzry",
    component: _a2cd7b76,
    name: "gj-wzry"
  }, {
    path: "/jump",
    component: _8fb28d16,
    name: "jump"
  }, {
    path: "/kfyz",
    component: _809749c2,
    name: "kfyz"
  }, {
    path: "/log",
    component: _73080b95,
    name: "log"
  }, {
    path: "/login",
    component: _7fffbc9c,
    name: "login"
  }, {
    path: "/moreAnchor",
    component: _394004f1,
    name: "moreAnchor"
  }, {
    path: "/news",
    component: _4ef184b0,
    name: "news"
  }, {
    path: "/register",
    component: _0da241ea,
    name: "register"
  }, {
    path: "/test",
    component: _f9f7904e,
    name: "test"
  }, {
    path: "/wares",
    component: _6be4ca43,
    name: "wares"
  }, {
    path: "/Bargbuy/Babuy",
    component: _119615dc,
    name: "Bargbuy-Babuy"
  }, {
    path: "/Bargbuy/Partialpayment",
    component: _1bb7ed40,
    name: "Bargbuy-Partialpayment"
  }, {
    path: "/black/blackList",
    component: _65cf29b6,
    name: "black-blackList"
  }, {
    path: "/black/center",
    component: _0ffe1c9d,
    name: "black-center"
  }, {
    path: "/black/releaseBlack",
    component: _8897b200,
    name: "black-releaseBlack"
  }, {
    path: "/black/zhgs",
    component: _87ae0b3c,
    name: "black-zhgs"
  }, {
    path: "/center/buyers",
    component: _e0e703f4,
    name: "center-buyers"
  }, {
    path: "/center/sell",
    component: _926860d8,
    name: "center-sell"
  }, {
    path: "/dealshow/selltime",
    component: _28cd5e62,
    name: "dealshow-selltime"
  }, {
    path: "/fastGold/fastGoldOrder",
    component: _1b516d7f,
    name: "fastGold-fastGoldOrder"
  }, {
    path: "/gj-wzry/hpjyEvaluator",
    component: _08d2bd0b,
    name: "gj-wzry-hpjyEvaluator"
  }, {
    path: "/specilPage/createAccountInfo",
    component: _308dee3b,
    name: "specilPage-createAccountInfo"
  }, {
    path: "/specilPage/downloadApp",
    component: _bb63ea56,
    name: "specilPage-downloadApp"
  }, {
    path: "/specilPage/hpjyEvaluator",
    component: _5ba4d774,
    name: "specilPage-hpjyEvaluator"
  }, {
    path: "/center/activity/play",
    component: _170ca2df,
    name: "center-activity-play"
  }, {
    path: "/center/assets/assetsFinancing",
    component: _f560a6f6,
    name: "center-assets-assetsFinancing"
  }, {
    path: "/center/buyers/BuyBargain",
    component: _0771a970,
    name: "center-buyers-BuyBargain"
  }, {
    path: "/center/buyers/buyBargainGoods",
    component: _d9070784,
    name: "center-buyers-buyBargainGoods"
  }, {
    path: "/center/buyers/buyComplaint",
    component: _73c20c7e,
    name: "center-buyers-buyComplaint"
  }, {
    path: "/center/buyers/buyFastGold",
    component: _128a93b6,
    name: "center-buyers-buyFastGold"
  }, {
    path: "/center/buyers/buyFastGoldDetail",
    component: _1383adb2,
    name: "center-buyers-buyFastGoldDetail"
  }, {
    path: "/center/buyers/buyGoodsOrder",
    component: _5b05b3f2,
    name: "center-buyers-buyGoodsOrder"
  }, {
    path: "/center/buyers/buyGuarantee",
    component: _8d38f5c4,
    name: "center-buyers-buyGuarantee"
  }, {
    path: "/center/buyers/buyLikedGoods",
    component: _84e074fa,
    name: "center-buyers-buyLikedGoods"
  }, {
    path: "/center/buyers/buyOrderDetail",
    component: _4516e4d5,
    name: "center-buyers-buyOrderDetail"
  }, {
    path: "/center/buyers/buyOrderInfo",
    component: _073665b2,
    name: "center-buyers-buyOrderInfo"
  }, {
    path: "/center/buyers/buySeckillOrder",
    component: _1cd8bfb9,
    name: "center-buyers-buySeckillOrder"
  }, {
    path: "/center/buyers/lotteryRecords",
    component: _06eb2696,
    name: "center-buyers-lotteryRecords"
  }, {
    path: "/center/buyers/sellerOrderInfo",
    component: _353e9d11,
    name: "center-buyers-sellerOrderInfo"
  }, {
    path: "/center/extend/extendMyExtension",
    component: _6848be1a,
    name: "center-extend-extendMyExtension"
  }, {
    path: "/center/extend/qrCode",
    component: _39d2de24,
    name: "center-extend-qrCode"
  }, {
    path: "/center/extend/sellgooSn",
    component: _7488542a,
    name: "center-extend-sellgooSn"
  }, {
    path: "/center/feedback/complaintcustomerservice",
    component: _2861732d,
    name: "center-feedback-complaintcustomerservice"
  }, {
    path: "/center/feedback/feedbackandsuggestion",
    component: _2acdb665,
    name: "center-feedback-feedbackandsuggestion"
  }, {
    path: "/center/feedback/feedbackrecording",
    component: _16c8c18a,
    name: "center-feedback-feedbackrecording"
  }, {
    path: "/center/log/history",
    component: _dcbeb5a8,
    name: "center-log-history"
  }, {
    path: "/center/myself/accountSecurity",
    component: _0fc341f9,
    name: "center-myself-accountSecurity"
  }, {
    path: "/center/myself/buyIdentity",
    component: _57e8fdc0,
    name: "center-myself-buyIdentity"
  }, {
    path: "/center/myself/cashWithdrawal",
    component: _0574eec8,
    name: "center-myself-cashWithdrawal"
  }, {
    path: "/center/myself/changeEmail",
    component: _67c73228,
    name: "center-myself-changeEmail"
  }, {
    path: "/center/myself/changePayPassword",
    component: _9bd3e922,
    name: "center-myself-changePayPassword"
  }, {
    path: "/center/myself/changePhone",
    component: _a7e22a0c,
    name: "center-myself-changePhone"
  }, {
    path: "/center/myself/depositWithdraw",
    component: _99a777b8,
    name: "center-myself-depositWithdraw"
  }, {
    path: "/center/myself/identityAuthentication",
    component: _d7353fac,
    name: "center-myself-identityAuthentication"
  }, {
    path: "/center/myself/myselfInfo",
    component: _93aa29cc,
    name: "center-myself-myselfInfo"
  }, {
    path: "/center/myself/recharge",
    component: _12f40c7b,
    name: "center-myself-recharge"
  }, {
    path: "/center/myself/thirdPartyBinding",
    component: _a52c3fbc,
    name: "center-myself-thirdPartyBinding"
  }, {
    path: "/center/sell/sellBargainGoods",
    component: _62af8104,
    name: "center-sell-sellBargainGoods"
  }, {
    path: "/center/sell/sellOrder",
    component: _2da357de,
    name: "center-sell-sellOrder"
  }, {
    path: "/center/sell/sellOutGoods",
    component: _a2d58170,
    name: "center-sell-sellOutGoods"
  }, {
    path: "/center/sell/Sellreceive",
    component: _13ba22f3,
    name: "center-sell-Sellreceive"
  }, {
    path: "/center/sell/sellSuccess",
    component: _374f5fda,
    name: "center-sell-sellSuccess"
  }, {
    path: "/center/sell/sellTab",
    component: _67ae1ab6,
    name: "center-sell-sellTab"
  }, {
    path: "/center/myself/accountSecurity/changePassword",
    component: _9c1e0e58,
    name: "center-myself-accountSecurity-changePassword"
  }, {
    path: "/center/myself/accountSecurity/changePhone",
    component: _1035b385,
    name: "center-myself-accountSecurity-changePhone"
  }, {
    path: "/",
    component: _134f02ca,
    name: "index"
  }, {
    path: "/black/:sn",
    component: _42c2b662,
    name: "black-sn"
  }, {
    path: "/detail/:sn?",
    component: _0dab8352,
    name: "detail-sn"
  }, {
    path: "/JDetail/:sn?",
    component: _c7e13634,
    name: "JDetail-sn"
  }, {
    path: "/news/:code",
    component: _4b5c22bc,
    name: "news-code"
  }, {
    path: "/goods/:sn?/order",
    component: _196d749a,
    name: "goods-sn-order"
  }, {
    path: "/goods/:sn?/pay",
    component: _4b54fd66,
    name: "goods-sn-pay"
  }, {
    path: "/goods/:sn?/success",
    component: _4f8bc6b0,
    name: "goods-sn-success"
  }, {
    path: "/wares/:sn/order",
    component: _3f8febf3,
    name: "wares-sn-order"
  }, {
    path: "/wares/:sn/pay",
    component: _6ede90e6,
    name: "wares-sn-pay"
  }, {
    path: "/wares/:sn/success",
    component: _2d7032e8,
    name: "wares-sn-success"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
